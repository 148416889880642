/* Color definitions */
:root {
	--primary: #2e4468;
	--primarydark: #4a618691;
	--primaryhalf: #526c96;
	--primarylight: #2e4468af;
	--primarysuperlight: #2e4468;
	--secondary: #3c763d;
	--secondarymedium: #2b552c;
	--secondarydark: #162c17;
	--secondarylight: #77df79;
	--secondaryhalf: #54a857;
	--tertiary: #2e4468;
	--tertiarylight: #7285a3;
	--quartary: #ca5800;
	--quartarydark: #351802;
	--quartarylight: #c49978;
}

/* latin-ext */
@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 400;
	src: local("Open Sans Italic"), local("OpenSans-Italic"), url("https://fonts.gstatic.com/s/opensans/v15/mem6YaGs126MiZpBA-UFUK0Xdc1UAw.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 400;
	src: local("Open Sans Italic"), local("OpenSans-Italic"), url("https://fonts.gstatic.com/s/opensans/v15/mem6YaGs126MiZpBA-UFUK0Zdc0.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Open Sans Regular"), local("OpenSans-Regular"), url("https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFW50bbck.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Open Sans Regular"), local("OpenSans-Regular"), url("https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0b.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	src: local("Open Sans Bold"), local("OpenSans-Bold"), url("https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	src: local("Open Sans Bold"), local("OpenSans-Bold"), url("https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 800;
	src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN8rsOXOhpOqc.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 800;
	src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN8rsOUuhp.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: "Open Sans Condensed";
	font-style: italic;
	font-weight: 300;
	src: local("Open Sans Condensed Light Italic"), local("OpenSansCondensed-LightItalic"), url("https://fonts.gstatic.com/s/opensanscondensed/v12/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suD8S72V6As.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Open Sans Condensed";
	font-style: italic;
	font-weight: 300;
	src: local("Open Sans Condensed Light Italic"), local("OpenSansCondensed-LightItalic"), url("https://fonts.gstatic.com/s/opensanscondensed/v12/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suD8Rb2V.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 300;
	src: local("Open Sans Condensed Light"), local("OpenSansCondensed-Light"), url("https://fonts.gstatic.com/s/opensanscondensed/v12/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMR6WR.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 300;
	src: local("Open Sans Condensed Light"), local("OpenSansCondensed-Light"), url("https://fonts.gstatic.com/s/opensanscondensed/v12/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMRw.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 700;
	src: local("Open Sans Condensed Bold"), local("OpenSansCondensed-Bold"), url("https://fonts.gstatic.com/s/opensanscondensed/v12/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuXMRw.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Open Sans";
}

h1 {
	font-family: "Open Sans Condensed";
}

h2 {
	font-size: 36px;
	color: var(--secondary);
	font-family: "Open Sans Condensed";
	font-weight: 800;
}

h3 {
	font-family: "Open Sans Condensed";
	font-size: 24px;
	color: var(--tertiary);
	font-weight: 800;
}

h4 {
	font-family: "Open Sans Condensed";
	color: var(--quartary);
}

h5 {
	font-family: "Open Sans Condensed";
}

.btn-primary {
	filter: saturate(90%);
	background-color: #005fd3;
	border-color: #005fd3;
}

.badge-success {
	background-color: #0a8927;
}

.nav-link {
	color: #0363c9;
}

.nav-pills .nav-link.active {
	background-color: #0070f4 !important;
}

.progress-bar {
	background-color: #0363c9;
}

label {
	font-weight: 600;
	font-stretch: extra-expanded;
	color: var(--primary);
}

.centered {
	display: block;
	margin: 0 auto;
}

.title {
	font-weight: 600;
	font-stretch: extra-expanded;
	color: var(--primary);
}

.empty-button {
	background: none;
	border: none;
	cursor: pointer;
}

#popover-contained {
	padding: 5px;
}

.modal-100w {
	max-width: none !important;
	width: 100%;
	margin: 0;
	padding: 0;
}

.modal-90w {
	max-width: none !important;
	width: 90%;
}

.modal-75w {
	max-width: none !important;
	width: 75%;
}

.modal-60w {
	max-width: none !important;
	width: 60%;
}

.modal-50w {
	max-width: none !important;
	width: 50%;
}

.modal-40w {
	max-width: none !important;
	width: 40%;
}

.modal-30w {
	max-width: none !important;
	width: 30%;
}

#popover-data .arrow {
	display: none;
}

.modal-close {
	font-weight: 700;
	line-height: 1;
}

a {
	color: #016ee3;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.badge-primary {
	background-color: #016ee3;
}

.media {
	display: flex;
	align-items: flex-start;
}

.media-body {
	-ms-flex: 1;
	flex: 1;
}

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;
}
