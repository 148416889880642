.language-switcher {
	position: absolute;
	bottom: 0;
	right: 2px;
	padding-top: 1px;
	padding-bottom: 0;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 13px;
}
